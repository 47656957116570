import React, { useEffect, useState, useRef } from "react";
import styled from 'styled-components';
import { Slider } from 'antd';
import FileUpload from './FileUpload';
import 'antd/dist/antd.css';

const MODE_SHOW_ORIGINAL = 'MODE_SHOW_ORIGINAL';
const MODE_SHOW_PROCESSED = 'MODE_SHOW_PROCESSED';
const MODE_SHOW_SIDE_BY_SIDE = 'MODE_SHOW_SIDE_BY_SIDE';

const KEY_SLIDER_BRIGHTNESS = 'KEY_SLIDER_BRIGHTNESS';
const KEY_SLIDER_CONTRAST = 'KEY_SLIDER_CONTRAST';
const KEY_SLIDER_TEMPERATURE = 'KEY_SLIDER_TEMPERATURE';
const KEY_SLIDER_TINT = 'KEY_SLIDER_TINT';

const Container = styled.div`
  flex: 1;
  display: flex;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: black;
  padding: 20px;
`;

const Padding = styled.div`

`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const Sliders = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderOption = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderLabel = styled.h5`

`;

const clamp = (number) => {
  return Math.min(Math.max(number, 0), 255);
};

const Canvas = () => {
  const canvasRef = useRef();

  //====== State ======
  let [mouse, setMouse] = useState({ x: undefined, y: undefined });
  let [screen, setScreen] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const { width, height } = screen;
  let [mode, setMode] = useState(MODE_SHOW_PROCESSED);
  let [sliderValues, setSliderValues] = useState({
    [KEY_SLIDER_BRIGHTNESS]: 0.0,
    [KEY_SLIDER_CONTRAST]: 0.0,
    [KEY_SLIDER_TEMPERATURE]: 0.0,
    [KEY_SLIDER_TINT]: 0.0,
  });
  let [imageDatas, setImageDatas] = useState({
    width: null,
    height: null,
    original: null,
    processed: null,
  });

  //keep track of xy position of mouse
  const onMouseMove = event => {
    setMouse({ x: event.x, y: event.y });
  };
  // refresh canvas on screen resize
  const onResize = context => {
    setScreen({ width: window.innerWidth, height: window.innerHeight });
    // init(context); // call draw/animate functions on resize
  };

  useEffect(() => {}, []);
  // ============== useEffect holds all your activity ==============
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    window.addEventListener("resize", () => onResize(context));
    window.addEventListener("mousemove", e => onMouseMove(e));
    canvas.width = screen.width - 200;
    canvas.height = screen.height;

    // call your drawing/animating functions here
    // init(context);
    drawImageDataOnCanvas();
  }, [screen]);

  const copyImageData = (imageData) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const newImageData = context.createImageData(imageData.width, imageData.height);
    newImageData.data.set(imageData.data);
    return newImageData;
  };

  const drawImageDataOnCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (mode === MODE_SHOW_ORIGINAL) {
      const { original: originalImageData } = imageDatas;
      if (originalImageData) {
        console.log('Draw original on canvas triggered - image data exists');
        context.putImageData(originalImageData, 0, 0);
      }
    } else {
      const { processed: processedImageData } = imageDatas;
      if (processedImageData) {
        console.log('Draw processed on canvas triggered - image data exists');
        context.putImageData(processedImageData, 0, 0);
      }
    }
  };

  useEffect(
    drawImageDataOnCanvas,
    [mode, imageDatas],
  );

  // const init = context => {
  //   drawImageDataOnCanvas();
  // };

  const createImageDataFromFileData = (fileData) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const image = new Image();
    image.onload = () => {
      const imageWidth = image.width;
      const imageHeight = image.height;
      console.log({imageWidth, imageHeight});
      // context.drawImage(image, 0, 0, imageWidth, imageHeight);
      const scaledHeight = screen.height;
      const scaledWidth = imageWidth / imageHeight * scaledHeight;
      context.drawImage(image, 0, 0, scaledWidth, scaledHeight);
      const imageData = context.getImageData(0, 0, scaledWidth, scaledHeight);
      setImageDatas({
        width: imageWidth,
        height: imageHeight,
        original: imageData,
        processed: imageData,
      });
    };
    image.src = fileData;
  };

  const toggleMode = () => {
    if (mode === MODE_SHOW_ORIGINAL) {
      setMode(MODE_SHOW_PROCESSED);
    } else {
      setMode(MODE_SHOW_ORIGINAL);
    }
  };

  const applyGrayscale = () => {
    const { original: originalImageData } = imageDatas;
    if (originalImageData) {
      const newImageData = copyImageData(originalImageData);
      const data = newImageData.data;
      console.log('Apply grayscale triggered - bytes exist');
      console.log(data);
      console.log(data.length);
      for (var i = 0; i < data.length; i += 4) {
        var brightness = 0.34 * data[i] + 0.5 * data[i + 1] + 0.16 * data[i + 2];
        // console.log(data[i]);
        // console.log(brightness);
        // red
        data[i] = brightness;
        // green
        data[i + 1] = brightness;
        // blue
        data[i + 2] = brightness;
      }

      setImageDatas({
        ...imageDatas,
        processed: newImageData,
      });
    }
  };

  const applyPreset = () => {
    setSliderValues({
      ...sliderValues,
      [KEY_SLIDER_BRIGHTNESS]: 0.5,
      [KEY_SLIDER_CONTRAST]: 25,
      [KEY_SLIDER_TEMPERATURE]: 10,
      [KEY_SLIDER_TINT]: 10,
    });
  };

  const downloadProcessed = () => {
    const link = document.createElement('a');
    link.download = 'processed.png';
    link.href = canvasRef.current.toDataURL('image/png');
    link.click();
  };

  const onChangeSlider = (sliderKey, value) => {
    if (isNaN(value)) {
      return;
    }
    setSliderValues({
      ...sliderValues,
      [sliderKey]: value,
    });
  };

  const applySliders = () => {
    const { original: originalImageData } = imageDatas;
    if (originalImageData) {
      const newImageData = copyImageData(originalImageData);
      const data = newImageData.data;
      console.log('Apply sliders triggered - bytes exist');
      console.log(data);
      console.log(data.length);

      const sliderValueBrightness = sliderValues[KEY_SLIDER_BRIGHTNESS];
      const delta = (127.0 / 5.0) * sliderValueBrightness;

      for (let i = 0; i < data.length; i += 4) {
        // red
        data[i] = clamp(data[i] + delta);
        // green
        data[i + 1] = clamp(data[i + 1] + delta);
        // blue
        data[i + 2] = clamp(data[i + 2] + delta);
      }

      const sliderValueContrast = sliderValues[KEY_SLIDER_CONTRAST];
      const correctionFactor = (259.0 * (sliderValueContrast + 255.0)) / (255.0 * (259.0 - sliderValueContrast));

      for (let i = 0; i < data.length; i += 4) {
        // red
        data[i] = clamp((correctionFactor * (data[i] - 128)) + 128);
        // green
        data[i + 1] = clamp((correctionFactor * (data[i + 1] - 128)) + 128);
        // blue
        data[i + 2] = clamp((correctionFactor * (data[i + 2] - 128)) + 128);
      }

      const sliderValueTemperature = sliderValues[KEY_SLIDER_TEMPERATURE];

      for (let i = 0; i < data.length; i += 4) {
        // red
        data[i] = clamp(data[i] + sliderValueTemperature);
        // green
        data[i + 1] = clamp(data[i + 1]);
        // blue
        data[i + 2] = clamp(data[i + 2] - sliderValueTemperature);
      }

      const sliderValueTint = sliderValues[KEY_SLIDER_TINT];

      for (let i = 0; i < data.length; i += 4) {
        // red
        data[i] = clamp(data[i]);
        // green
        data[i + 1] = clamp(data[i + 1] + sliderValueTint);
        // blue
        data[i + 2] = clamp(data[i + 2]);
      }

      setImageDatas({
        ...imageDatas,
        processed: newImageData,
      });
    }
  };

  useEffect(applySliders, [sliderValues]);

  return (
    <Container>
      <Content>
        <Padding>
          <canvas
            style={{
              width: '100%',
              height: '100%',
            }}
            ref={canvasRef}
          />
        </Padding>
      </Content>
      <Sidebar>
        <FileUpload
          createImageDataFromFileData={createImageDataFromFileData}
        />
        <button
          onClick={toggleMode}
        >
          Toggle mode
        </button>
        <button
          onClick={applyGrayscale}
        >
          Apply grayscale
        </button>
        <button
          onClick={applyPreset}
        >
          Apply preset
        </button>
        <button
          onClick={downloadProcessed}
        >
          Download image
        </button>
        <Sliders>
          <SliderOption>
            <SliderLabel>
              Exposure
            </SliderLabel>
            <Slider
              disabled={false}
              min={-5}
              max={5}
              step={0.01}
              value={sliderValues[KEY_SLIDER_BRIGHTNESS]}
              onChange={(value) => onChangeSlider(KEY_SLIDER_BRIGHTNESS, value)}
            />
          </SliderOption>
          <SliderOption>
            <SliderLabel>
              Contrast
            </SliderLabel>
            <Slider
              disabled={false}
              min={-255}
              max={255}
              step={1.0}
              value={sliderValues[KEY_SLIDER_CONTRAST]}
              onChange={(value) => onChangeSlider(KEY_SLIDER_CONTRAST, value)}
            />
          </SliderOption>
          <SliderOption>
            <SliderLabel>
              Temperature
            </SliderLabel>
            <Slider
              disabled={false}
              min={-100}
              max={100}
              step={1.0}
              value={sliderValues[KEY_SLIDER_TEMPERATURE]}
              onChange={(value) => onChangeSlider(KEY_SLIDER_TEMPERATURE, value)}
            />
          </SliderOption>
          <SliderOption>
            <SliderLabel>
              Tint
            </SliderLabel>
            <Slider
              disabled={false}
              min={-100}
              max={100}
              step={1.0}
              value={sliderValues[KEY_SLIDER_TINT]}
              onChange={(value) => onChangeSlider(KEY_SLIDER_TINT, value)}
            />
          </SliderOption>
        </Sliders>
      </Sidebar>
    </Container>
  );
};

export default Canvas;
