import React from 'react';
import styled from 'styled-components';
import Canvas from './Canvas'

const Container = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
`;

const App = () => {
  return (
    <Container className="App">
      <Canvas />
    </Container>
  );
}

export default App;
